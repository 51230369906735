<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">YEARLY DIVIDEND</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 class="mx-2 mt-4">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="year_of"
              class="mx-3 pt-5"
              :items="year_of_items"
              item-value="year"
              item-text="year"
              label="Year Of"
              required
              dense
              @change="get_list_of_new_member_items(0)"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="mx-2">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4 class="mx-3">
                        <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline" v-if="can_released && position==='ADMIN'">
                                                Generate
                                                <v-icon
                                                  class="mr-2"
                                                  color="success"
                                                  @click="released_data()"
                                                >
                                                {{icons.mdiCashCheck}}
                                                </v-icon>
                                            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-data-table
        class="pt-4"
        :height="height"
        :headers="headers"
        :items="list_of_new_member"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <span class="headline">Share's: {{total_shares}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline">Member's Count: {{paid_members}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline">Total IC: {{total_ic}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline">Total PR: {{total_pr}}</span>
          </v-toolbar>
          <v-toolbar flat>
            <span class="headline">TASM: {{total_asm}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline">TIL: {{total_til}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <p class="font-weight-thin">TIL Excluded Terminated Account</p>
          </v-toolbar>
          <v-toolbar flat>
            <span class="headline">RIC: {{ric}}</span>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <span class="headline">RPR: {{rpr}}</span>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.no="{ item }">
          {{list_of_new_member.map(function(x) {return x.id; }).indexOf(item.id)+1}}
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter, mdiCashCheck} from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
          mdiCashCheck
        },
      }
    },
    mounted() {
      this.retrieve_all_transaction_year()
        .then((response) => {
          this.year_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return {
        selected_month_of: moment().format('MMMM DD, YYYY'),
        year_of_items: [],
        year_of: '',

        can_print: false,
        can_released: false,
        type_of_member: 'Regular Member',
        list_of_new_member: [],
        paid_members: 0,
        total_shares: 0,
        total_asm: 0,
        total_til: 0,
        total_ic: 0,
        total_pr: 0,
        total: 0,

        ric: 0,
        rpr: 0,
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'No', value: 'no'},
          {text: 'CMF #', value: 'cmf_no'},
          {text: 'Effective Date', value: 'date_accomplished'},
          {text: 'Name', value: 'name'},
          {text: 'Shares', value: 'shares', sortable: false},
          {text: 'ASM', value: 'asm', sortable: false},
          {text: 'Interest Loan', value: 'i_loan', sortable: false},
          {text: 'IC', value: 'ic', sortable: false},
          {text: 'PR', value: 'pr', sortable: false},
          {text: 'Total', value: 'total', sortable: false},
        ],
        height: 430
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'month_of', 'position']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.type_of_member = ''
        this.list_of_new_member = []
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_all_transaction_year']),
      ...mapActions('members_information', ['list_of_members_have_dividend_yearly']),
      ...mapActions('regular_savings_data', ['register_savings_deposit_from_yearly_dividend']),
      get_list_of_new_member_items(released) {
        var mont = 'NA';
        if (this.year_of_items.length > 0) {
          var indexs = this.year_of_items.map(function (x) {
            return x.year
          }).indexOf(this.year_of)
          if (indexs != -1) {
            this.selected_month_of = 'December ' + this.year_of_items[indexs].year;
            mont = this.year_of_items[indexs].year;
          }
        }
        this.data_table_loading = true
        if (this.selected_month_of != 'NAN' && this.selected_month_of != 'NA' && mont != 'NA'
          && this.type_of_member != '') {
          const data = new FormData()
          data.append('month_of', mont);
          data.append('selected_month', this.selected_month_of);
          data.append('type_of_member', this.type_of_member);
          data.append('branch_id', this.branch_id);
          data.append('get_available_dividend', 1);
          data.append('released', released);
          this.list_of_members_have_dividend_yearly(data)
            .then((response) => {
              this.can_print = true
              this.list_of_new_member = response.data[0].data
              this.total_shares = response.data[0].total_shares
              this.total_asm = response.data[0].total_asm
              this.total_til = response.data[0].total_til
              this.total_ic = response.data[0].total_ic
              this.total_pr = response.data[0].total_pr
              this.total = response.data[0].total
              this.ric = response.data[0].ric
              this.rpr = response.data[0].rpr
              this.can_released = !response.data[0].already_release
              this.paid_members = this.list_of_new_member.length
              this.data_table_loading = false
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      released_data() {
        if (confirm('Are you sure you want to release dividend?')) {
          if (this.list_of_new_member.length > 0) {
            this.get_list_of_new_member_items(1)
          } else {
            alert('No function in this matter')
          }
        }
      },
      print_data() {
        var imgData = this.company_logo
        var array = []

        var month = this.year_of
        array.push(
          [
            {text: 'No', alignment: 'center', style: 'label'},
            {text: 'Control No', alignment: 'center', style: 'label'},
            {text: 'Effective Date', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Shares', alignment: 'center', style: 'label'},
            {text: 'ASM', alignment: 'center', style: 'label'},
            {text: 'Interest Loan', alignment: 'center', style: 'label'},
            {text: 'IC', alignment: 'center', style: 'label'},
            {text: 'PR', alignment: 'center', style: 'label'},
            {text: 'Receivable', alignment: 'center', style: 'label'},
          ]
        )
        var total_share = this.total_shares
        var total_as = this.total_asm
        var total_i_loa = this.total_til
        var total_i = this.total_ic
        var total_p = this.total_pr
        var tota = this.total
        if (this.list_of_new_member.length > 0) {
          this.list_of_new_member.forEach(function (item, index, payment) {
            array.push(
              [
                {text: index + 1, alignment: 'center'},
                {text: item.cmf_no, alignment: 'left'},
                {text: item.date_accomplished, alignment: 'left'},
                {
                  text: item.name,
                  alignment: 'left'
                },
                {text: item.shares, alignment: 'center'},
                {text: item.asm, alignment: 'center'},
                {text: item.i_loan, alignment: 'center'},
                {text: item.ic, alignment: 'center'},
                {text: item.pr, alignment: 'center'},
                {text: item.total, alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_share,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_as,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_i_loa,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_i,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_p,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                  {
                    text: tota,
                    alignment: 'center',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'YEARLY DIVIDEND', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Year of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [30, 40, 65, 80, 40, 40, 40, 40, 40, 40],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
